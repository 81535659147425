<template>
  <div class="fs-container">
    <div class="fs-section fs-news">
      <div class="fs-inr news-inr">
        <div class="fs-title">
          <h1>i-NEWS</h1>
        </div>

        <!-- PC show 6 cuc -->
        <div class="nav-news">
          <ul>
            <li
              v-for="(blogCategory, stt) in ListBlogCategories"
              :key="stt"
              :class="blogCategory.Id == currentCategory ? 'active' : ''"
            >
              <router-link :to="$t('Route.INews') + '/' + blogCategory.Slug">
                <h2>{{ blogCategory.Name }}</h2>
              </router-link>
            </li>
            <li :class="recruitmentCategoryActive ? 'active' : ''">
              <router-link :to="$t('Route.Recruit')">
                <h2>{{ $t("Common.Recruitment") }}</h2>
              </router-link>
            </li>
            <li :class="setupAPNCategoryActive ? 'active' : ''">
              <router-link :to="$t('Route.SetupAPN')">
                <h2>{{ $t("Common.SetupAPN") }}</h2>
              </router-link>
            </li>
          </ul>
        </div>

        <template
          v-if="
            currentCategory > 0 &&
            ListBlogCategories.find((i) => i.Id == currentCategory)
              .LayoutType == 1 &&
            !showGrid
          "
        >
          <router-link
            class="main-news news-item"
            v-if="MainBlog.length > 0"
            :to="
              $t('Route.INews') +
              '/' +
              MainBlog[0].BlogCategorySlug +
              '/' +
              MainBlog[0].Slug
            "
          >
            <div class="fs-pic">
              <img
                class="fs-pc pcPic"
                :src="MainBlog[0].Thumbnail"
                :alt="MainBlog[0].Title"
              />
              <img
                class="fs-sp spPic"
                :src="MainBlog[0].Thumbnail"
                :alt="MainBlog[0].Title"
              />
            </div>
            <div class="fs-txt">
              <div class="user-box">
                <div class="user-pic">
                  <img
                    :src="MainBlog[0].AuthorIcon"
                    :alt="MainBlog[0].Author"
                  />
                </div>
                <div class="user-txt">{{ MainBlog[0].Author }}</div>
              </div>
              <h3 v-html="MainBlog[0].Title"></h3>
            </div>
          </router-link>

          <div class="hot-news" v-if="currentCategory > 0">
            <h2 class="title">{{ $t("Common.HOT NEWS") }}</h2>
            <router-link
              class="view-more"
              :to="
                $t('Route.INews') +
                '/' +
                ListBlogCategories.find((i) => i.Id == currentCategory).Slug +
                '/' +
                $t('Route.ViewMore') +
                '/tin-noi-bat'
              "
              >{{ $t("Common.See all") }}
            </router-link>
            <div class="hot-items">
              <router-link
                class="hot-item news-item"
                v-for="(blog, i) of ListFeatureBlog"
                :key="blog.Id"
                :to="
                  $t('Route.INews') +
                  '/' +
                  blog.BlogCategorySlug +
                  '/' +
                  blog.Slug
                "
              >
                <div
                  class="fs-bg cmBg"
                  :style="'background-image:url(' + blog.Thumbnail + ')'"
                ></div>
                <div v-if="i != 1 && i != 2" class="fs-txt">
                  <h3 v-html="blog.Title"></h3>
                  <!-- <span class="hot-caption">{{blog.ReadTime}} Min Read</span> -->
                </div>
                <div v-if="i == 1 || i == 2" class="fs-txt">
                  <div class="user-box">
                    <div class="user-pic">
                      <img :src="blog.AuthorIcon" :alt="blog.Author" />
                    </div>
                    <div class="user-txt">{{ blog.Author }}</div>
                  </div>
                  <h3 v-html="blog.Title"></h3>
                </div>
              </router-link>
            </div>
          </div>

          <div
            class="i-videos"
            v-if="ListVideoBlog.length > 0 && currentCategory > 0"
          >
            <h2 class="title">i-VIDEO</h2>
            <router-link
              class="view-more"
              :to="
                $t('Route.INews') +
                '/' +
                ListBlogCategories.find((i) => i.Id == currentCategory).Slug +
                '/' +
                $t('Route.ViewMore') +
                '/i-video'
              "
              >{{ $t("Common.See all") }}</router-link
            >
            <div class="video-block">
              <div class="video-main news-item" @click="playVideo()">
                <div
                  class="fs-bg cmBg"
                  :style="
                    'background-image:url(' +
                    ListVideoBlog[currentVideo].Thumbnail +
                    ')'
                  "
                ></div>
                <div class="fs-txt">
                  <div class="user-box">
                    <div class="user-pic">
                      <img
                        :src="ListVideoBlog[currentVideo].AuthorIcon"
                        :alt="ListVideoBlog[currentVideo].Author"
                      />
                    </div>
                    <div class="user-txt">
                      {{ ListVideoBlog[currentVideo].Author }}
                    </div>
                  </div>
                  <h3 v-html="ListVideoBlog[currentVideo].Title"></h3>
                </div>
                <div
                  class="video-but type-red open-inline"
                  :data-video="ListVideoBlog[currentVideo].VideoUrl"
                ></div>
                <div class="video-inline">
                  <iframe
                    id="mainVideo"
                    src=""
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>

              <div class="video-list">
                <div
                  class="video-item"
                  v-for="(videoBlog, i) of ListVideoBlog"
                  :class="currentVideo == i ? 'active' : ''"
                  @click="currentVideo = i"
                  :key="videoBlog.Id"
                >
                  <div class="fs-pic">
                    <img :src="videoBlog.Thumbnail" :alt="videoBlog.Author" />
                    <div
                      class="video-but type-grey open-inline"
                      :data-video="videoBlog.VideoUrl"
                    ></div>
                  </div>
                  <div class="fs-txt">
                    <h3 v-html="videoBlog.Title"></h3>
                    <p>{{ videoBlog.Tagging }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="i-magazine"
            v-if="ListMagazineBlog.length > 0 && currentCategory > 0"
          >
            <h2 class="title">i-MAGAZINE</h2>
            <router-link
              class="view-more"
              :to="
                $t('Route.INews') +
                '/' +
                ListBlogCategories.find((i) => i.Id == currentCategory).Slug +
                '/' +
                $t('Route.ViewMore') +
                '/i-magazine'
              "
              >{{ $t("Common.See all") }}</router-link
            >
            <div class="magazine-items">
              <router-link
                class="magazine-item news-item"
                :to="
                  $t('Route.INews') +
                  '/' +
                  magazineBlog.BlogCategorySlug +
                  '/' +
                  magazineBlog.Slug
                "
                v-for="magazineBlog of ListMagazineBlog"
                :key="magazineBlog.Id"
              >
                <div
                  class="fs-bg cmBg"
                  :style="
                    'background-image:url(' + magazineBlog.Thumbnail + ')'
                  "
                ></div>
                <div class="fs-txt">
                  <div class="user-box">
                    <div class="user-pic">
                      <img
                        :src="magazineBlog.AuthorIcon"
                        :alt="magazineBlog.Author"
                      />
                    </div>
                    <div class="user-txt">{{ magazineBlog.Author }}</div>
                  </div>
                  <h3 v-html="magazineBlog.Title"></h3>
                </div>
              </router-link>
            </div>
          </div>
        </template>

        <template
          v-if="
            (currentCategory > 0 &&
              ListBlogCategories.find((i) => i.Id == currentCategory)
                .LayoutType == 2) ||
            showGrid
          "
        >
          <div class="news-list">
            <template v-for="blog of BlockBlog">
              <a
                :key="blog.Id"
                v-if="blog.IsTypeVideo"
                class="news-item type-video"
              >
                <div
                  class="fs-bg cmBg"
                  :style="'background-image:url(' + blog.Thumbnail + ')'"
                ></div>
                <div class="fs-txt">
                  <h3 v-html="blog.Title"></h3>
                  <p v-html="blog.Brief"></p>
                </div>
                <div class="user-box">
                  <div class="user-pic">
                    <img :src="blog.AuthorIcon" :alt="blog.Author" />
                  </div>
                  <div class="user-txt">{{ blog.Author }}</div>
                </div>
                <div class="news-type">I-VIDEO</div>
                <div
                  class="video-but open-video-pop"
                  @click="openVideo(blog.VideoUrl)"
                  :data-video="blog.VideoUrl"
                ></div>
              </a>
              <router-link
                :key="blog.Id"
                v-if="!blog.IsTypeVideo"
                class="news-item"
                :to="
                  $t('Route.INews') +
                  '/' +
                  blog.BlogCategorySlug +
                  '/' +
                  blog.Slug
                "
              >
                <div
                  class="fs-bg cmBg"
                  :style="'background-image:url(' + blog.Thumbnail + ')'"
                ></div>
                <div class="fs-txt">
                  <h3 v-html="blog.Title"></h3>
                  <p v-html="blog.Brief"></p>
                </div>
                <div class="user-box">
                  <div class="user-pic">
                    <img :src="blog.AuthorIcon" :alt="blog.Author" />
                  </div>
                  <div class="user-txt">blog.Author</div>
                </div>
              </router-link>
            </template>
          </div>

          <!--Video Popup-->
          <div :class="'modal ' + modalClass" id="video-modal">
            <div class="overlay" @click="closeVideo()"></div>
            <div class="modal-inner">
              <a class="close-button" @click="closeVideo()"></a>
              <iframe
                :src="videoId"
                allow="autoplay; encrypted-media"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </template>

        <template v-if="currentCategory == 0 && recruitmentCategoryActive">
          <div class="news-list">
            <router-link
              class="news-item"
              :key="recruitment.Id"
              v-for="recruitment in Recruitment"
              :to="$t('Route.Recruit') + '/' + recruitment.Slug"
            >
              <div
                class="fs-bg cmBg"
                :style="'background-image:url(' + recruitment.Thumbnail + ')'"
              ></div>

              <div class="fs-txt">
                <h3 v-html="recruitment.Title"></h3>
                <p>
                  {{ recruitment.Brief }}
                </p>
              </div>

              <div class="user-box">
                <div class="user-pic">
                  <img
                    :src="recruitment.AuthorIcon"
                    :alt="recruitment.Author"
                  />
                </div>
                <div class="user-txt">{{ recruitment.Author }}</div>
              </div>
            </router-link>
          </div>
        </template>
        <template v-if="currentCategory == 0 && setupAPNCategoryActive">
          <div>
            <h3 class="txt-title">HƯỚNG DẪN CÀI ĐẶT APN</h3>
            <p class="apn-small-text">
              Hướng dẫn cài đặt APN trong một nốt nhạc cho tất cả dòng máy, Bạn
              đã biết chưa?
            </p>
            <b class="apn-bold-text"
              >APN là gì vậy? Tại sao phải cài đặt APN?</b
            >
            <p class="apn-small-text">
              APN trong tiếng Anh có nghĩa là Access Point Name - điểm truy cập.
              Nếu Bạn đã đăng ký 4G iTel mà vẫn chưa thể truy cập internet, thì
              nguyên nhân có thể là do thiết bị chưa được cài đặt điểm truy cập
              APN. Đừng lo lắng, hãy cài đặt APN ngay để kết nối internet, phục
              vụ cho nhu cầu giải trí và làm việc hàng ngày của Bạn!
            </p>
          </div>
          <div>
            <h3 class="apn-bold-text">
              Chi tiết cách cài đặt APN với từng dòng máy điện thoại
            </h3>
            <div>
              
                <b-button
                  href="huong-dan-cai-dat-apn/iphone"
                  class="m-1 button-choose"
                  size="sm"
                  :variant="
                    choosePhoneType == 'iphone' ? 'danger' : 'outline-primary'
                  "
                  >iphone</b-button
                >
                <b-button
                  href="huong-dan-cai-dat-apn/Samsung"
                  class="m-1 button-choose"
                  size="sm"
                  :variant="
                    choosePhoneType == 'Samsung' ? 'danger' : 'outline-primary'
                  "
                  >Samsung</b-button
                >
                <b-button
                  href="huong-dan-cai-dat-apn/Xiaomi"
                  class="m-1"
                  size="sm"
                  :variant="
                    choosePhoneType == 'Xiaomi' ? 'danger' : 'outline-primary'
                  "
                  >Xiaomi</b-button
                >
                <b-button
                  href="huong-dan-cai-dat-apn/Huawei"
                  class="m-1 button-choose"
                  size="sm"
                  :variant="
                    choosePhoneType == 'Huawei' ? 'danger' : 'outline-primary'
                  "
                  >Huawei</b-button
                >
                <b-button
                  href="huong-dan-cai-dat-apn/Oppo"
                  class="m-1 button-choose"
                  size="sm"
                  :variant="
                    choosePhoneType == 'Oppo' ? 'danger' : 'outline-primary'
                  "
                  >Oppo</b-button
                >
                <b-button
                  href="huong-dan-cai-dat-apn/Google"
                  class="m-1"
                  size="sm"
                  :variant="
                    choosePhoneType == 'Google' ? 'danger' : 'outline-primary'
                  "
                  >Google</b-button
                >
            </div>
            <p class="apn-small-text">
              Lựa chọn dòng máy điện thoại của Bạn để xem chi tiết các bước cài
              đặt tương ứng nhé!
            </p>
          </div>
          <div>
            <ul class="d-md-flex">
              <li v-for="(chooseAPN, stt) in chooseAPNdata" :key="stt">
                <div class="apn-step">
                  <div class="apn-step-image">
                    <img
                      class="apn-image"
                      :src="chooseAPN.url"
                      alt="Hướng dẫn cài đặt APN"
                    />
                  </div>
                  <div class="apn-step-content">
                    <div class="apn-step-title">
                      {{ chooseAPN.step }}
                    </div>
                    <div
                      v-html="chooseAPN.description"
                      class="apn-step-description"
                    ></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="apn-finish">
            <h3 class="apn-bold-text">Lời kết</h3>
            <p class="apn-small-text">
              Mong rằng những hướng dẫn phía trên của iTel sẽ giúp Bạn dễ dàng
              hơn khi cài đặt APN cho điện thoại. Nếu vẫn còn khó khăn, đừng
              quên đội ngũ iTel luôn túc trực 24/7. Hãy gọi tới Hotline 0877 087
              087 (Miễn phí cho thuê bao iTel) hoặc chat trực tiếp trên Website
              để được hỗ trợ tức thời nhé! Còn trường hợp Bạn đã thử và thành
              công, hãy chia sẻ kinh nghiệm với mọi người và đừng ngần ngại cho
              iTel đánh giá chất lượng dịch vụ 7 sao tuyệt vời nha!
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import { BFormInput, BFormGroup, BButton, BButtonGroup } from "bootstrap-vue";
import { isMobile } from "mobile-device-detect";

// Import Swiper styles
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    Swiper,
    SwiperSlide,
    BButton,
    BButtonGroup,
  },
  data() {
    return {
      ListBlogCategories: [],
      MainBlog: [],
      ListFeatureBlog: [],
      ListVideoBlog: [],
      ListMagazineBlog: [],
      BlockBlog: [],
      Recruitment: [],
      currentVideo: 0,
      currentCategory: 0,
      videoId: "",
      modalClass: "",
      recruitmentCategoryActive: false,
      setupAPNCategoryActive: false,
      showGrid: false,
      showGridType: "",
      choosePhoneType: "iphone",

      guideAPNiphone: [
        {
          step: "Bước 1",
          description: "Trên điện thoại của bạn, truy cập vào mục “Cài đặt”.",
          url: require("@/assets/images/apn/iphone/Step1.png"),
        },
        {
          step: "Bước 2",
          description: 'Tại mục “Cài đặt”, chọn mục “Di động".',
          url: require("@/assets/images/apn/iphone/Step2.png"),
        },
        {
          step: "Bước 3",
          url: require("@/assets/images/apn/iphone/Step3.png"),
          description:
            'Trong trường hợp điện thoại có 2 SIM, Bạn chọn SIM muốn thực hiện cài đặt APN sau đó chọn “Mạng dữ liệu di động".',
        },
        {
          step: "Bước 4",
          url: require("@/assets/images/apn/iphone/Step4.png"),
          description:
            "Tại màn hình “Dữ liệu di động” nhập:<br/>- Dữ liệu di động APN: “m9-itelecom”, các trường khác bỏ trống.<br/>- Thiết lập LTE (Tùy chọn) APN: “m9-itelecom”, các trường khác bỏ trống.<br/>Sau đó bật lại “Dữ liệu di động” và sử dụng Internet.",
        },
      ],
      guideAPNSamsung: [
        {
          step: "Bước 1",
          url: require("@/assets/images/apn/samsung/Step1.png"),
          description:
            'Trên điện thoại của Bạn, truy cập mục vào “Cài đặt” > chọn mục “Kết nối".',
        },
        {
          step: "Bước 2",
          url: require("@/assets/images/apn/samsung/Step2.png"),
          description: 'Tại đây, chọn tiếp mục “Các mạng di động".',
        },
        {
          step: "Bước 3",
          url: require("@/assets/images/apn/samsung/Step3.png"),
          description:
            "Tiếp theo, chọn “Điểm truy cập”. Trong trường hợp điện thoại có 2 SIM, Bạn chọn SIM muốn thực hiện cài đặt APN.",
        },
        {
          step: "Bước 4",
          url: require("@/assets/images/apn/samsung/Step4.png"),
          description:
            "Cuối cùng, Bạn chọn “Thêm” để tạo điểm truy cập mới.<br/>Nhập APN: “m9-itelecom”, các trường khác bỏ trống.<br/>Sau đó lưu APN mới tạo và khởi động lại thiết bị để sử dụng Internet.",
        },
      ],
      guideAPNxiaomi: [
        {
          step: "Bước 1",
          url: require("@/assets/images/apn/xiaomi/Step1.png"),
          description:
            'Trên điện thoại của Bạn, truy cập vào mục “Cài đặt” > chọn mục “Mạng di động".',
        },
        {
          step: "Bước 2",
          url: require("@/assets/images/apn/xiaomi/Step2.png"),
          description:
            "Tại đây, chọn tiếp mục “Thẻ SIM và mạng di động”, tại màn hình này, Bạn chọn thẻ Sim cần cài đặt APN.",
        },
        {
          step: "Bước 3",
          url: require("@/assets/images/apn/xiaomi/Step3.png"),
          description:
            'Sau khi vào mục “Cài đặt thẻ SIM”, Bạn chọn mục “Tên điểm truy cập".',
        },
        {
          step: "Bước 4",
          url: require("@/assets/images/apn/xiaomi/Step4.png"),
          description:
            "Tại đây, Bạn nhập thông tin APN là “m9-itelecom”, ấn quay lại để thiết bị lưu thông tin.<br/>Chọn APN mới tạo và bật dữ liệu mạng để sử dụng Internet.",
        },
      ],
      guideAPNhuawei: [
        {
          step: "Bước 1",
          url: require("@/assets/images/apn/huawei/Step1.png"),
          description:
            "Trên điện thoại của Bạn, truy cập vào mục “Cài đặt” và chọn chức năng “Không dây & mạng”",
        },
        {
          step: "Bước 2",
          url: require("@/assets/images/apn/huawei/Step2.png"),
          description: "Tiếp theo, Bạn chọn chức năng “Mạng di động”",
        },
        {
          step: "Bước 3",
          url: require("@/assets/images/apn/huawei/Step3.png"),
          description:
            "Tại màn hình “Mạng di động”, Bạn tiếp tục chọn chức năng “Tên điểm truy cập (APN)”",
        },
        {
          step: "Bước 4",
          url: require("@/assets/images/apn/huawei/Step4.png"),
          description:
            "Ấn vào biểu tượng “ba chấm” ở góc trên bên phải màn hình rồi chọn “Tạo mới điểm truy cập”, nhập thông tin APN là “m9-itelecom”.<br/>Sau đó, ấn quay lại để thiết bị tự lưu APN mới tạo, bật dữ liệu mạng để sử dụng Internet.",
        },
      ],
      guideAPNoppo: [
        {
          step: "Bước 1",
          url: require("@/assets/images/apn/oppo/Step1.png"),
          description:
            "Trên điện thoại của Bạn, truy cập vào mục “Cài đặt” và chọn chức năng “Thẻ SIM và dữ liệu di động”",
        },
        {
          step: "Bước 2",
          url: require("@/assets/images/apn/oppo/Step2.png"),
          description: "Tiếp theo, Bạn hãy chọn Sim muốn thực hiện cài đặt APN",
        },
        {
          step: "Bước 3",
          url: require("@/assets/images/apn/oppo/Step3.png"),
          description: "Tiếp tục chọn chức năng “Tên điểm truy cập” ở bước này",
        },
        {
          step: "Bước 4",
          url: require("@/assets/images/apn/oppo/Step4.png"),
          description:
            "Tại đây, hãy chọn chức năng “Thêm” để tạo điểm truy cập mới và nhập thông tin APN là “m9-itelecom”.<br/>Sau đó lưu APN mới tạo và khởi động lại thiết bị là Bạn đã có thể sử dụng internet bình thường rồi!",
        },
      ],
      guideAPNgoogle: [
        {
          step: "Bước 1",
          url: require("@/assets/images/apn/google/Step1.png"),
          description:
            "Trên điện thoại của Bạn, truy cập vào mục “Cài đặt” và chọn chức năng “Mạng và Internet”",
        },
        {
          step: "Bước 2",
          url: require("@/assets/images/apn/google/Step2.png"),
          description: "Tiếp theo, Bạn hãy chọn Sim muốn thực hiện cài đặt APN",
        },
        {
          step: "Bước 3",
          url: require("@/assets/images/apn/google/Step3.png"),
          description:
            "Tại bước này, hãy chọn “Nâng cao”, sau đó chọn “Tên điểm truy cập”",
        },
        {
          step: "Bước 4",
          url: require("@/assets/images/apn/google/Step4.png"),
          description:
            "Ấn vào biểu tượng “ba chấm” ở góc trên bên phải màn hình rồi chọn “APN mới”, nhập thông tin Tên là “iTel” và APN là “m9-iTelecom”.<br/>Sau đó lưu APN mới tạo và khởi động lại thiết bị là Bạn đã có thể sử dụng internet bình thường rồi!",
        },
      ],
      chooseAPNdata: [
        {
          step: "Bước 1",
          description: "Trên điện thoại của bạn, truy cập vào mục “Cài đặt”.",
          url: require("@/assets/images/apn/iphone/Step1.png"),
        },
        {
          step: "Bước 2",
          description: 'Tại mục “Cài đặt”, chọn mục “Di động".',
          url: require("@/assets/images/apn/iphone/Step2.png"),
        },
        {
          step: "Bước 3",
          url: require("@/assets/images/apn/iphone/Step3.png"),
          description:
            'Trong trường hợp điện thoại có 2 SIM, Bạn chọn SIM muốn thực hiện cài đặt APN sau đó chọn “Mạng dữ liệu di động".',
        },
        {
          step: "Bước 4",
          url: require("@/assets/images/apn/iphone/Step4.png"),
          description:
            "Tại màn hình “Dữ liệu di động” nhập:<br/>- Dữ liệu di động APN: “m9-itelecom”, các trường khác bỏ trống.<br/>- Thiết lập LTE (Tùy chọn) APN: “m9-itelecom”, các trường khác bỏ trống.<br/>Sau đó bật lại “Dữ liệu di động” và sử dụng Internet.",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      this.fetch();
    },
    currentCategory: function (i, v) {
      console.log("current category", i, v);
      this.loading(true);
      this.getData();
    },
    showGridType: function (i, v) {
      this.loading(true);
      this.getData();
    },
    currentVideo: function (i, v) {
      this.playVideo();
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    // fetch data recruitment category
    if (this.$route.name == "tuyen-dung") {
      this.fetchDataRecuitment();
    }
    console.log("routeName", this.$route.name);
    if (this.$route.name == "huong-dan-cai-dat-apn") {
      this.setupAPNCategoryActive = true;
      this.recruitmentCategoryActive = false;
      this.chooseAPNdata = this.guideAPNiphone;
      this.choosePhoneType = "iphone";
    }
    if (this.$route.name == "huong-dan-cai-dat-apn-chi-tiet") {
      this.setupAPNCategoryActive = true;
      this.recruitmentCategoryActive = false;
      if (this.$route.params.phoneType == "iphone") {
        this.chooseAPNdata = this.guideAPNiphone;
        this.choosePhoneType = "iphone";
      }
      if (this.$route.params.phoneType == "Samsung") {
        this.chooseAPNdata = this.guideAPNSamsung;
        this.choosePhoneType = "Samsung";
      }
      if (this.$route.params.phoneType == "Xiaomi") {
        this.chooseAPNdata = this.guideAPNxiaomi;
        this.choosePhoneType = "Xiaomi";
      }
      if (this.$route.params.phoneType == "Huawei") {
        this.chooseAPNdata = this.guideAPNhuawei;
        this.choosePhoneType = "Huawei";
      }
      if (this.$route.params.phoneType == "Oppo") {
        this.chooseAPNdata = this.guideAPNoppo;
        this.choosePhoneType = "Oppo";
      }
      if (this.$route.params.phoneType == "Google") {
        this.chooseAPNdata = this.guideAPNgoogle;
        this.choosePhoneType = "Google";
      }
    }
    this.fetch();
    var t_ = this;
    EventBus.$on("CHANGE_LANG", function (payLoad) {
      t_.fetch();
    });
  },
  methods: {
    Img(pic) {
      return images[pic];
    },
    getData() {
      if (this.currentCategory > 0) {
        if (
          this.ListBlogCategories.find((i) => i.Id == this.currentCategory)
            .LayoutType == 1
        ) {
          useJwt
            .post("getMasterData/listBlog", {
              columnFilters: { BlogCategoryId: this.currentCategory },
              sort: [],
              page: 1,
              pageSize: 1000,
              lang: this.$l,
            })
            .then((response) => {
              this.MainBlog = response.data.result.MainBlog;
              this.ListFeatureBlog = response.data.result.ListFeatureBlog;
              this.ListVideoBlog = response.data.result.ListVideoBlog;
              this.ListMagazineBlog = response.data.result.ListMagazineBlog;
              if (this.showGridType == "tin-noi-bat") {
                this.BlockBlog = this.ListFeatureBlog;
              } else if (this.showGridType == "i-video") {
                this.BlockBlog = this.ListVideoBlog;
              } else if (this.showGridType == "i-magazine") {
                this.BlockBlog = this.ListMagazineBlog;
              }
              this.loading(false);
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
                { icon: true, closeButton: "button" }
              );
            });
        } else if (
          this.ListBlogCategories.find((i) => i.Id == this.currentCategory)
            .LayoutType == 2
        ) {
          useJwt
            .post("getMasterData/blockBlog", {
              columnFilters: { BlogCategoryId: this.currentCategory },
              sort: [],
              page: 1,
              pageSize: 1000,
              lang: this.$l,
            })
            .then((response) => {
              this.BlockBlog = response.data.result.BlockBlog;
              console.log("BlockBlog", this.BlockBlog);
              this.loading(false);
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
                { icon: true, closeButton: "button" }
              );
            });
        }
      } else {
        this.fetchDataRecuitment();
      }
    },

    fetch() {
      useJwt
        .post("getMasterData/category", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.ListBlogCategories = response.data.result.ListBlogCategories;

          if (this.$route.params.Type != undefined) {
            this.showGrid = true;
            this.showGridType = this.$route.params.Type;
          } else {
            this.showGrid = false;
            this.showGridType = "";
          }
          if (
            this.$route.params.Id != undefined &&
            this.$route.params.Id != ""
          ) {
            var f = this.ListBlogCategories.find(
              (i) => i.Slug == this.$route.params.Id
            );
            if (f != undefined) {
              this.currentCategory = f.Id;
            } else {
              this.currentCategory = this.ListBlogCategories[0].Id;
            }
            this.recruitmentCategoryActive = false;
            this.setupAPNCategoryActive = false;
          } else if (
            this.$route.meta.isRecruit != undefined &&
            this.$route.meta.isRecruit
          ) {
            console.log("vao roter app parram1 ");
            this.currentCategory = 0;
            this.recruitmentCategoryActive = true;
            this.setupAPNCategoryActive = false;  
          } else if (
            this.$route.meta.isSetupAPN != undefined &&
            this.$route.meta.isSetupAPN
          ) {
            console.log("vao roter app parram3 ");
            this.currentCategory = 0;
            this.setupAPNCategoryActive = true;
            this.recruitmentCategoryActive = false;
           
          } else {
            console.log("vao roter app parram2 ");
            this.currentCategory = this.ListBlogCategories[0].Id;
            this.recruitmentCategoryActive = false;
          }
        });
    },
    fetchDataRecuitment() {
      useJwt
        .post("getMasterData/Recruitment", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.Recruitment = response.data.result.Recruitment;
          console.log(this.Recruitment);
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    playVideo() {
      const video = document.getElementsByClassName("video-main news-item");
      video[0].children[3].children[0].setAttribute(
        "src",
        "https://www.youtube.com/embed/" +
          this.ListVideoBlog[this.currentVideo].VideoUrl +
          "?rel=0&amp;autoplay=1&amp;playsinline=1"
      );
      if (!video[0].classList.contains("show-video")) {
        video[0].classList.add("show-video");
      }
    },
    openVideo(url) {
      this.modalClass = "modal__open";
      this.videoId =
        "https://www.youtube.com/embed/" +
        url +
        "?rel=0&amp;autoplay=1&amp;playsinline=1";
    },
    closeVideo() {
      this.videoId = "";
      this.modalClass = "";
    },
  },
};
</script>

<style>
.txt-title {
  /* Subtitle/Large */
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */
  /* Neutral/800 */
  color: #181818;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 8px;
}
.apn-small-text {
  /* Body Small/Regular */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* Neutral/700 */
  color: #1f1f1f;
  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 16px;
}
.apn-bold-text {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1f1f1f;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 8px;
}
.apn-step-image {
  /* Block Image */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.apn-image {
  width: 90%;
  object-fit: contain;
}
.apn-step-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.apn-step-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* Neutral/700 */
  color: #1f1f1f;
  /* Inside auto layout */
  max-width: 250px;
  order: 2;
  align-self: stretch;
  margin-bottom: 16px;
  text-justify: auto;
}
.apn-finish {
  margin-top: 12px;
}
@media screen and (max-width: 767px) {
  .apn-image {
    width: 100%;
    object-fit: contain;
  }
  .apn-step-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1f1f1f;
    max-width: 330px;
    order: 2;
    align-self: stretch;
    margin-bottom: 16px;
    text-justify: auto;
  }
  
}
</style>